<script>

import AdvancedTable from "../../../components/elements/advanced-table.vue";
import {mapGetters} from "vuex";

export default {
    components: {
        AdvancedTable,
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        }),

        fields: function () {
            return [
                {
                    key: "partner",
                    label: this.$t('interactions::meetings.relationships.partner').ucFirst(),
                    formatter: (value) => value && value.name,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "user",
                    label: this.$t('interactions::meetings.relationships.user').ucFirst(),
                    formatter: (value) => value && value.name,
                    thClass: '',
                    sortable: true
                },
                {
                    key: "date",
                    label: this.$t('interactions::meetings.columns.date').ucFirst(),
                    formatter: (value) => value && value.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "realized_at",
                    label: this.$t('interactions::meetings.columns.realized_at').ucFirst(),
                    formatter: (value) => value && value.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "canceled_at",
                    label: this.$t('interactions::meetings.columns.canceled_at').ucFirst(),
                    formatter: (value) => value && value.printDate(this.locale),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "successful",
                    label: this.$t('interactions::meetings.columns.successful').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "notification",
                    label: this.$t('interactions::meetings.columns.notification').ucFirst(),
                    formatter: (value) => value && value.truncate(100),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "is_active",
                    label: this.$t('interactions::meetings.columns.is_active').ucFirst(),
                    thClass: '',
                    sortable: true
                },
                {
                    key: "actions",
                    label: this.$t('base.actions').ucFirst(),
                    thClass: '',
                    tdClass: '',
                    sortable: false
                },
            ];
        },
    },

    data() {
        return {};
    },

    methods: {
        translateMorphs: function (morph) {
            switch (morph) {
                case 'App\\Models\\User':
                    return this.$tc('users.user', 2).ucFirst()
                case 'Appon\\Partners\\Models\\Partner':
                    return this.$tc('partners::partners.partner', 2).ucFirst()
                default:
                    return ''
            }
        },
    },

    mounted() {
        this.$store.dispatch('app/title', {key: 'interactions::meetings.meeting', count: 2})
    },

    unmounted() {
        this.$store.dispatch('app/title', '')
    }
};
</script>

<template>
    <advanced-table
        :caption="$tc('interactions::meetings.meeting', 2).ucFirst()"
        :current-page="1"
        :edit-link="{ name: 'accessible-meeting-edit' }"
        :empty-filtered-text="$t('base.empty_filtered').ucFirst()"
        :empty-text="$t('base.empty_table').ucFirst()"
        :fields="fields"
        :queries="{ with: ['partner', 'user'] }"
        :per-page="10"
        borderless
        deletable
        editable
        hover
        action="accessible"
        moduleNamespace="meeting"
        module-pager="accessiblePager"
        outlined
        permission="meetings"
        primary-key="id"
        responsive="sm"
        searchable
        show-empty
        sort-by="id"
        sort-direction="asc"
        sort-icon-left
    >
    </advanced-table>
</template>
